class Main {
    constructor() {
        this.selectors = {
            document: document,
            form: '.petitii-form #subscribe-form',
            thanksDiv : '.petitii-form #newsletter-contacts-success',
            comment: 'input[name=comment]'
        };
        this.init();
        this.events();
    };

    init() {
        this.getNodes();
    }

    getNodes() {
        var _this = this;
        this.nodes = {};
        $.each(this.selectors, function(key, selector) {
            _this.nodes[key] = $(selector);
        });
    }
    events() {
        var self = this;

        self.nodes.form.on('submit',function(e){
            e.preventDefault();
            var $this;
            $this = $(e.currentTarget);
            self.nodes.comment.val(`Adresă: ${$('input[name=address]').val()},\n Cerere = ${$('textarea[name=message]').val()}`);
            console.log($this.serialize());
            self.xhr = $.ajax({
              url: $this.attr("action"),
              type: "POST",
              beforeSend: function() {
                $("#js-submit-errors").empty();
              },
              data: $this.serialize(),
              cache: false,
              success: function(response) {
                if (response.success == true){
                    self.nodes.thanksDiv.removeClass('hidden');
                    self.nodes.thanksDiv.fadeIn(400,() =>{
                        setTimeout(()=>{
                            self.nodes.thanksDiv.fadeOut(400)
                        },3000)
                    })
                    $this[0].reset();
                }
              }
            });
        });
    }

}

new Main();
